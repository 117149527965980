<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">FEES </span> LIST
    </PageHeader>

    <v-row>
      <v-col>
        <Fees />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-form ref="editFeesForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template #item.academic_calendar="{ item }">
              {{ item.calendar.academic_calendar }}
            </template>
            <template #item.fullprogram="{ item }">
              {{ item.program.program_name_certificate }}
            </template>
            <template #item.fresher_fees_local_foreign="{ item }">
              <v-icon>₵</v-icon> {{ item.fresh_student_fees_local }}
              <v-icon>₵</v-icon>
              {{ item.fresh_student_fees_foreign }}
            </template>
            <template #item.continuing_fees_local_foreign="{ item }">
              <v-icon>₵</v-icon> {{ item.continuing_student_fees_local }}
              <v-icon>₵</v-icon>
              {{ item.continuing_student_fees_foreign }}
            </template>
            <template #item.deadline="{ item }">
              <v-edit-dialog large>
                <div color="primary">Deadline(s)</div>
                <template #input>
                  <v-row
                    class="my-1"
                    v-for="(row, i) in item.enroldeadlines"
                    :key="i"
                  >
                    <v-col>
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            readonly
                            dense
                            v-model="row.deadline"
                            clearable
                            label="Date"
                            hide-details="auto"
                            hide-selected
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            :rules="[(v) => !!v || 'Date is required...']"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="row.deadline"
                          @input="menu3 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-text-field
                        hide-details="auto"
                        readonly
                        outlined
                        dense
                        label="Percentage"
                        :value="row.percent"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog
                v-if="
                  item.calendar.current &&
                  getters_abilities.includes('update_fees_access')
                "
                large
                @open="
                  editedFees = {
                    edited_fresh_student_fees_local:
                      item.fresh_student_fees_local,
                    edited_fresh_student_fees_foreign:
                      item.fresh_student_fees_foreign,
                    edited_continuing_student_fees_local:
                      item.continuing_student_fees_local,
                    edited_continuing_student_fees_foreign:
                      item.continuing_student_fees_foreign,
                  }
                "
                @save="saveProgramFeesChangesBtn(item.id, editedFees)"
              >
                <v-btn
                  dark
                  right
                  large
                  icon
                  :disabled="getters_abilities.includes('update_fees_access')"
                >
                  <v-icon dark class="primary--text">
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-0 ma-0">
                      <v-row>
                        <v-col>
                          <v-card outlined class="mt-3">
                            <v-card-title primary-title>
                              Fresh Student Fees
                            </v-card-title>
                            <v-card-text>
                              <v-text-field
                                class="mb-4"
                                v-model.number="
                                  editedFees.edited_fresh_student_fees_local
                                "
                                type="number"
                                outlined
                                hide-details="auto"
                                prepend-inner-icon="₵"
                                label="Gh New Cedis"
                              ></v-text-field>
                              <v-text-field
                                outlined
                                hide-details="auto"
                                prepend-inner-icon="₵"
                                label="US Dollars"
                                type="number"
                                v-model.number="
                                  editedFees.edited_fresh_student_fees_foreign
                                "
                              ></v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card outlined class="mt-3">
                            <v-card-title primary-title>
                              Continuing Student Fees
                            </v-card-title>
                            <v-card-text>
                              <v-text-field
                                class="mb-4"
                                type="number"
                                v-model.number="
                                  editedFees.edited_continuing_student_fees_local
                                "
                                outlined
                                hide-details="auto"
                                prepend-inner-icon="₵"
                                label="Gh New Cedis"
                              ></v-text-field>
                              <v-text-field
                                type="number"
                                v-model.number="
                                  editedFees.edited_continuing_student_fees_foreign
                                "
                                outlined
                                hide-details="auto"
                                prepend-inner-icon="₵"
                                label="US Dollars"
                              ></v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <!-- 	<v-text-field
												class="mt-5"
												v-model="adminPassword"
												label="Enter Administrator's password"
												outlined
												type="password"
												@keyup="ifAdmin"
												@blur="removeError"
											>
												<template v-slot:append>
													<v-fade-transition>
														<v-progress-circular
															v-if="adminResponse"
															color="info"
															size="24"
															indeterminate
														></v-progress-circular>
													</v-fade-transition>
												</template>
											</v-text-field> -->
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-spacer></v-spacer>
                      <!-- <v-btn
												:disabled="isadmin"
												color="error"
												@click="removeFeesBtn(item.id)"
											>
												Delete
											</v-btn> -->
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold"
                >No Academic Fees available</span
              >
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Fees />
      </v-col>
    </v-row>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { computed, getCurrentInstance, provide, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import PageHeader from "@/components/slots/PageHeader";
  import Fees from "@/components/Pagination/Fees";
  export default {
    components: { Response, PageHeader, Fees },
    setup() {
      const vm = getCurrentInstance()
      const { getFees, removeFees, saveFeesChanges, signOut } = useActions([
        "getFees",
        "removeFees",
        "saveFees",
        "saveFeesChanges",
        "signOut",
      ]);

      const {
        getters_fees,
        getters_feesalreadyexist,
        getters_program,
        getters_abilities,
      } = useGetters([
        "getters_fees",
        "user",
        "getters_feesalreadyexist",
        "getters_curcalendar",
        "getters_program",
        "getters_abilities",
      ]);

      const Fees = reactive({
        feeArray: ["local", "foreign"],
      });

      const feesForm = reactive({
        basket_calendar: [],
        chkfees: false,
        fresh_student_fees_local: null,
        fresh_student_fees_foreign: null,
        continuing_student_fees_local: null,
        continuing_student_fees_foreign: null,
        calendar: new Date().toISOString().substr(0, 10),
        program: null,
        error_msgs: "",
        basket: {},
        clickOnce: false,
        loading: false,
        absolute: true,
        overlay: false,
        basketDeadline: [
          {
            date: new Date().toISOString().substr(0, 10),
            error: "",
            percent: 0,
            menu2: false,
          },
        ],
        showDeadline: true,
        errormsgsDeadline: [],
        deadlineBasket: [],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
        response: false,
      });

      const editFeesForm = reactive({
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        adminResponse: false,
        headers: [
          {
            text: "ACADEMIC CALENDAR",
            value: "academic_calendar",
          },
          {
            text: "PROGRAM",
            value: "fullprogram",
          },
          {
            text: "FRESH STUDENT FEES",
            value: "fresher_fees_local_foreign",
            align: "center",
          },
          {
            text: "CONTINUING STUDENT FEES",
            value: "continuing_fees_local_foreign",
            align: "center",
          },
          //	{ text: "DEADLINE(S)", value: "deadline" },

          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        editedFees: {
          edited_fresh_student_fees_local: 0,
          edited_fresh_student_fees_foreign: 0,
          edited_continuing_student_fees_local: 0,
          edited_continuing_student_fees_foreign: 0,
        },
      });

      let menu3 = ref(false);

      let { isLoaded } = toRefs(editFeesForm);

      const { msgHeader, msgBody, msgIcon, response, basket_calendar, color } =
        toRefs(feesForm);

      const removeFeesBtn = (id) => {
        response.value = false;
        removeFees(id)
          .then(() => {
            getFees()
              .then(() => {
                isLoaded.value = false;
              })
              .catch((e) => {
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                color.value = "error";
                switch (e.response.status) {
                  case 423:
                    msgBody.value = e.response.data.message;
                    signOut().then(() => {
                      vm.proxy.$router.replace({
                        name: "Login",
                      });
                    });
                    break;
                  case 403:
                    msgBody.value = e.response.data.message;
                    break;

                  default:
                    msgBody.value =
                      "Try Again Later Or Call The System Administrator";
                    break;
                }
              });
          })
          .catch((e) => {
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;

              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          })
          .finally(() => (response.value = true));
      };

      provide("color", color);

      getFees()
        .then(() => {
          isLoaded.value = false;
        })
        .catch((e) => {
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          color.value = "error";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;
            case 403:
              msgBody.value = e.response.data.message;
              break;

            default:
              msgBody.value = "Try Again Later Or Call The System Administrator";
              break;
          }
        });

      const basketWithIndex = computed(() => {
        return getters_fees.value.map((items /* , index */) => ({
          ...items,
          /* 	index: index + 1, */
        }));
      });

      const saveProgramFeesChangesBtn = (id, item) => {
        response.value = false;

        saveFeesChanges({ id: id, itemfees: item }).catch((e) => {
          response.value = true;
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          color.value = "error";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;
            case 403:
              msgBody.value = e.response.data.message;
              break;

            default:
              msgBody.value = "Try Again Later Or Call The System Administrator";
              break;
          }
        });
      };

      return {
        ...toRefs(feesForm),
        ...toRefs(editFeesForm),
        basketWithIndex,
        removeFeesBtn,
        ...toRefs(Fees),
        basket_calendar,
        getters_program,
        getters_feesalreadyexist,
        menu3,
        saveProgramFeesChangesBtn,
        getters_abilities,
      };
    },
  };
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
</style>
