<template>
	<div>
		<v-row>
			<v-col cols="12">
				<v-pagination
					v-model="getters_fees_meta.current_page"
					:length="getters_fees_meta.last_page"
					@input="getFees(getters_fees_meta.current_page)"
				></v-pagination>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { useActions, useGetters } from "vuex-composition-helpers";
export default {
	setup() {
		const { getFees } = useActions(["getFees"]);

		const { getters_fees_meta } = useGetters(["getters_fees_meta"]);

		return {
			getFees,
			getters_fees_meta,
		};
	},
};
</script>
